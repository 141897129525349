import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageEnum } from 'src/app/pem-shared/enum/local-storage-keys.enum';

@Component({
  selector: 'app-unsaved-audience-confirm-modal',
  templateUrl: './unsaved-audience-confirm-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `.unsaved-audience-backdrop {
      z-index: 1050 !important;
    }`,
  ],
})
export class UnsavedAudienceConfirmModalComponent {
  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly router: Router,
  ) {}

  /**
   * dismissModal method is to dismiss the modal with reason
   * @param reason Why the modal is dismissed
   */
  public dismissModal(reason: string): void {
    this.activeModal.dismiss(reason);
  }

  /**
   * confirm method is to navigate the user to respective route the user is trying to access to
   */
  public confirm() {
    const nextState = sessionStorage.getItem(LocalStorageEnum.NEXT_STATE);
    sessionStorage.removeItem(LocalStorageEnum.NEXT_STATE);
    sessionStorage.removeItem(LocalStorageEnum.SAVE_AUDIENCE);
    if (nextState) {
      this.activeModal.close();
      this.router.navigate([nextState]).then(()=> {
        window.scrollTo({
          top: 2,
          behavior: 'smooth',
        });
      });
    } else this.activeModal.close();
  }
}
