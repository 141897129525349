/**
 * This enum is used for referring sub navigation other than the primary navigation.
 */
export enum SubNavigationEnum {
  TEMPLATE_LIST = 'template-list',
  CAMPAIGN = 'campaign',
  MY_CAMPAIGN = 'my-campaigns',
  ALL_CAMPAIGN = 'all-campaigns',
  FAVORITE_CAMPAIGN = 'favorite-campaigns',
  OVERVIEW = 'overview',
  DASHBOARD = 'dashboard',
  REPORTS = 'reports',
  PERFORMANCE = 'performance',
  CHANNEL_ACTIVITY = 'channel-activity',
  AWARENESS_CONVERSIONS = 'awareness-conversions',
  CONSIDERATION_CONVERSIONS = 'consideration-conversions',
  DECISION_CONVERSIONS = 'decision-conversions',
  PATIENT_DEMOGRAPHICS = 'patient-demographics',
  EDIT_AUDIENCE = 'edit-audience',
  EDIT_TACTICS = 'edit-tactics',
  COST_SCHEDULE = 'cost-schedule',
  CAMPAIGN_REPORTING = 'campaign-reporting',
  LOOKUP_SEARCH = 'search',
  CONSUMER_PROFILE = 'consumer-profile',
  INTEGRATIONS = 'integrations',
  FILE_CONFIGURATIONS = 'file-configurations',
  FILE_UPLOADS = 'file-uploads',
}
