// Core Modules
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';

// External Modules
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';

// Services
import { ConfigService } from '../../core/services/config.service';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { HttpAdapterService } from '../../core/services/http-adapter.service';

// Models
import { IAudienceListResponseModel, IAudienceModel, IAudiencePopulationMetrics } from '../models/audience-config.model';

// Components
import { UnsavedAudienceConfirmModalComponent } from '../unsaved-audience-confirm-modal/unsaved-audience-confirm-modal.component';

@Injectable({
  providedIn: 'root',
})
export class AudienceService implements OnDestroy {

  // Private Members
  private readonly apiBaseUrl: string;
  private readonly audienceEndpoint: Record<string, object>;
  private unsavedAudienceModalRef!: NgbModalRef;

  // Public Members
  /**
   * To track pending status count
   */
  public pendingTemplateStatusCountSubject = new BehaviorSubject<string>('');
  /**
   * The constructor method
   * @param configService Config Service
   * @param errorHandlerService Error Handler Service
   * @param httpClient Http Client Service
   */
  constructor(
    private readonly configService: ConfigService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly httpAdapterService: HttpAdapterService,
    private readonly httpClient: HttpClient,
    private readonly modalService: NgbModal,
  ) {
    this.apiBaseUrl = this.configService.getAPIBaseUrl;
    this.audienceEndpoint = this.configService.getEndpointsByModule('audienceEndpoints');
  }

  /**
   * Get Audiences List
   * @param {({ [key: string]: string | number })} audiencesListPage
   * @return {*}  {Observable<IAudienceListResponseModel>}
   * @memberof AudienceService
   */
  public getAudiences(audiencesListPage: { [key: string]: string | number }, cacheKey?: string): Observable<IAudienceListResponseModel> {
    const {
      sortBy,
      searchTerm,
      templateGroup,
      templateStatus,
      sortDir,
      pageSize,
      page,
      view,
      campaignId,
    } = audiencesListPage;
    const appUrl = `${this.apiBaseUrl}${this.audienceEndpoint.audienceList}`;
    const queryParams = {
      ...campaignId && { campaignId },
      ...sortBy && ({ sort: sortDir === 'desc' ? `-${sortBy}` : sortBy }),
      ...searchTerm && { searchTerm },
      ...templateGroup && { templateGroup },
      ...templateStatus && { templateStatus },
      ...view && { view },
      ...pageSize && { pageSize },
      ...page && { page },
    };
    return this.httpAdapterService.get<IAudienceListResponseModel>(appUrl, { params: new HttpParams().appendAll(queryParams) }, cacheKey);
  }

  /**
   * Get Audience Information By using Id
   * @param {(Record<string, string | null>)} param
   * @return {*}  {Observable<IAudienceModel>}
   * @memberof AudienceService
   */
  public getAudience(param: Record<string, string | null>): Observable<IAudienceModel> {
    const { audienceId } = param;
    const appUrl = `${this.apiBaseUrl}${this.audienceEndpoint.audienceList}/${audienceId}`;
    return this.httpClient.get<IAudienceModel>(appUrl)
      .pipe(map(result => result),
        catchError(this.errorHandlerService.errorHandler));
  }

  /**
   * This method is used for make Patch request for the particular audience Eg. Approve, Reject
   * @param {Partial<IAudienceModel>} body
   * @param {number} id
   * @return {*}  {Observable<IAudienceListResponseModel>}
   * @memberof AudienceService
   */
  public patchAudience(body: Partial<IAudienceModel>, id: number): Observable<IAudienceModel> {
    const apiUrl = `${this.apiBaseUrl}${this.audienceEndpoint.audienceList}/${id}`;
    return this.httpClient.patch<IAudienceModel>(apiUrl, body)
      .pipe(map(result => result),
        catchError(this.errorHandlerService.errorHandler));
  }

  /**
   * This method is used for deleting the audience
   * @param {number} audienceId
   * @return {*}  {Observable<Object>}
   * @memberof AudienceService
   */
  public deleteAudience(audienceId: number): Observable<Object> {
    const apiUrl = `${this.apiBaseUrl}${this.audienceEndpoint.audienceList}/${audienceId}`;
    return this.httpClient.delete<Object>(apiUrl)
      .pipe(map(result => result),
        catchError(this.errorHandlerService.errorHandler));
  }

  /**
   * This method is used for creating audience
   * @param {Partial<IAudienceModel>} audience
   * @return {*}  {Observable<IAudienceModel>}
   * @memberof AudienceService
   */
  public createAudience(audience: Partial<IAudienceModel>): Observable<IAudienceModel> {
    const apiUrl = this.configService.getAPIBaseUrl + '/audiences/';
    return this.httpClient.post<IAudienceModel>(apiUrl, audience)
      .pipe(map(result => result),
        catchError(this.errorHandlerService.errorHandler));
  }

  public openUnsavedAudienceModal() {
    this.unsavedAudienceModalRef = this.modalService.open(UnsavedAudienceConfirmModalComponent, { backdropClass: 'unsaved-audience-backdrop', ariaLabelledBy: 'unsaved-audience-confirm-modal', size: 'sm' });
    this.unsavedAudienceModalRef.result.then(() => {
      const confirmElement = this.document.getElementById('sh-ea-confirm-mdl-yes-btn');
      if (confirmElement) confirmElement.click();
    }).catch(reason => {
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
      const cancelElement = this.document.getElementById('sh-ea-confirm-mdl-cancel-btn');
      if (cancelElement) cancelElement.click();
    });
  }

  public getDismissReason(reason: string | number): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  
  public getAudienceGroups(audienceId: number): Observable<IAudienceModel> {
    return this.httpAdapterService.get<IAudienceModel>(`${this.apiBaseUrl}/audiences/${audienceId}`);
  }
  
  private removeExtraProperties(criteriaDetails:any) {
    if (criteriaDetails.createdFromAudienceId === null) delete criteriaDetails.createdFromAudienceId;
    if (criteriaDetails.channelPrecedence === null) delete criteriaDetails.channelPrecedence;
    delete criteriaDetails.comments;
    if (Object.prototype.hasOwnProperty.call(criteriaDetails, 'campaignIsDeleted')) delete criteriaDetails.campaignIsDeleted;
  }

  updateSegment(criteriaDetails:Partial<IAudienceModel>):Observable<Partial<IAudienceModel>> {
    const url = `${this.apiBaseUrl}/audiences/${criteriaDetails.id}`;
    const payload = this.deleteUpdateSegmentPaylaodProperties(criteriaDetails);
    return this.httpAdapterService.put<Partial<IAudienceModel>>(url, payload);
  }

  private deleteUpdateSegmentPaylaodProperties(criteriaDetails:Record<string, any>) {
    if ( !criteriaDetails.createdFromAudienceId ) {
      delete criteriaDetails.createdFromAudienceId;
    }
    if ( !criteriaDetails.channelPrecedence ) {
      delete criteriaDetails.channelPrecedence;
    }
    delete criteriaDetails.comments;
    if (Object.prototype.hasOwnProperty.call(criteriaDetails, 'campaignIsDeleted')) {
      delete criteriaDetails.campaignIsDeleted;
    }
    return criteriaDetails;
  }

  public getPopulationMetrics(audience: IAudienceModel): Observable<IAudiencePopulationMetrics[]> {
    const payload = {
      segment: {
        criteria: audience.criteria,
      },
      remainderName: audience.remainderName,
      audienceGroups: audience.audienceGroups,
    };

    let params: Record<string, any> = {
      ...{ dns : audience.includeDoNotSolicit || false },
      ...{ control_group: audience.includeControlGroup || false },
      ...audience.type && { audience_type: audience.type },
    };
    if (audience.channelPrecedence?.length)  params.channel_precedence = audience?.channelPrecedence?.toString();
    return this.httpAdapterService.post<IAudiencePopulationMetrics[]>(`${this.apiBaseUrl}${this.audienceEndpoint.channelCount}`, payload, { params });
  }
  
  /**
   *
   * @param {IAudienceModel} body
   * @return {*}  {Observable<IAudienceModel>}
   * @memberof AudienceService
   */
  public updateAudience(body: IAudienceModel): Observable<IAudienceModel> {
    const url = `${this.apiBaseUrl}${this.audienceEndpoint.audienceList}/${body.id}`;
    this.removeExtraProperties(body);
    return this.httpAdapterService.put<IAudienceModel>(url, body);
  }

  ngOnDestroy() {
    this.pendingTemplateStatusCountSubject.complete();
  }

}
