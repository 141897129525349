import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AudienceService } from 'src/app/audience/services/audience.service';
import { LocalStorageEnum } from 'src/app/pem-shared/enum/local-storage-keys.enum';

export const UnsavedAudienceDetectorGuard : CanActivateFn = () => {
  const unsavedAudienceFlag = sessionStorage.getItem(LocalStorageEnum.SAVE_AUDIENCE);
  const nextState: string = sessionStorage.getItem(LocalStorageEnum.NEXT_STATE) as string;
  if (!unsavedAudienceFlag || !nextState) return true;
  const audienceService: AudienceService = inject(AudienceService);
  audienceService.openUnsavedAudienceModal();
  return !unsavedAudienceFlag;
};
